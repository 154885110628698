import React from 'react'

const HausSeries2 = () => {
  return (
    <svg
      width="443"
      height="211"
      viewBox="0 0 443 211"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_332_56466)">
        <path
          d="M250.25 54.25L67.25 54.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M250.25 62.25L67.25 62.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M250.25 70.25L67.25 70.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M250.25 78.25L67.25 78.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M250.25 86.25L67.25 86.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M250.25 94.25L67.25 94.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M250.25 102.25L67.25 102.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M250.25 46.25L67.25 46.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M250.25 38.25L67.25 38.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M250.25 30.25L67.25 30.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M250.25 22.25L67.25 22.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M250.25 14.25L67.25 14.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M250.25 6.25L67.25 6.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M163.25 0.25L163.25 109.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M155.25 0.25L155.25 109.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M147.25 0.25L147.25 109.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M139.25 0.25L139.25 109.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M131.25 0.25L131.25 109.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M123.25 0.25L123.25 109.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M115.25 0.25L115.25 109.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M107.25 0.25L107.25 109.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M99.25 0.25L99.25 109.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M91.25 0.25L91.25 109.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M83.25 0.25L83.25 109.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M75.25 0.25L75.25 109.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M171.25 0.25L171.25 109.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M179.25 0.25L179.25 109.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M187.25 0.25L187.25 109.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M195.25 0.25L195.25 109.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M203.25 0.25L203.25 109.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M211.25 0.25L211.25 109.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M219.25 0.25L219.25 109.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M227.25 0.25L227.25 109.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M235.25 0.25L235.25 109.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M243.25 0.25L243.25 109.25"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M64.25 0.25L64.25 109.25L250.25 109.25L250.25 0.250002L64.25 0.25Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M75.25 158.25C75.25 157.698 74.8023 157.25 74.25 157.25C73.6977 157.25 73.25 157.698 73.25 158.25C73.25 158.802 73.6977 159.25 74.25 159.25C74.8023 159.25 75.25 158.802 75.25 158.25Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M84.25 158.25C84.25 157.145 83.3546 156.25 82.25 156.25C81.1454 156.25 80.25 157.145 80.25 158.25C80.25 159.355 81.1454 160.25 82.25 160.25C83.3546 160.25 84.25 159.355 84.25 158.25Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M83.25 158.25C83.25 157.698 82.8023 157.25 82.25 157.25C81.6977 157.25 81.25 157.698 81.25 158.25C81.25 158.802 81.6977 159.25 82.25 159.25C82.8023 159.25 83.25 158.802 83.25 158.25Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M73.25 158.25L70.25 158.25"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M86.25 158.25L83.25 158.25"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M80.25 158.25L75.25 158.25"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M74.25 154.25L74.25 157.25"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M82.25 154.25L82.25 157.25"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M82.25 159.25L82.25 165.25"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M74.25 159.25L74.25 165.25"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M80.25 167.25C80.25 166.145 79.3546 165.25 78.25 165.25C77.1454 165.25 76.25 166.145 76.25 167.25C76.25 168.355 77.1454 169.25 78.25 169.25C79.3546 169.25 80.25 168.355 80.25 167.25Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M79.25 167.25C79.25 166.698 78.8023 166.25 78.25 166.25C77.6977 166.25 77.25 166.698 77.25 167.25C77.25 167.802 77.6977 168.25 78.25 168.25C78.8023 168.25 79.25 167.802 79.25 167.25Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M70.25 167.25L77.25 167.25"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M79.25 167.25L86.25 167.25"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M78.25 166.25L78.25 154.25"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M75.25 175.25C75.25 174.698 74.8023 174.25 74.25 174.25C73.6977 174.25 73.25 174.698 73.25 175.25C73.25 175.802 73.6977 176.25 74.25 176.25C74.8023 176.25 75.25 175.802 75.25 175.25Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M84.25 175.25C84.25 174.145 83.3546 173.25 82.25 173.25C81.1454 173.25 80.25 174.145 80.25 175.25C80.25 176.355 81.1454 177.25 82.25 177.25C83.3546 177.25 84.25 176.355 84.25 175.25Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M83.25 175.25C83.25 174.698 82.8023 174.25 82.25 174.25C81.6977 174.25 81.25 174.698 81.25 175.25C81.25 175.802 81.6977 176.25 82.25 176.25C82.8023 176.25 83.25 175.802 83.25 175.25Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M73.25 175.25L70.25 175.25"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M86.25 175.25L83.25 175.25"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M80.25 175.25L75.25 175.25"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M74.25 179.25L74.25 176.25"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M82.25 179.25L82.25 176.25"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M82.25 174.25L82.25 169.25"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M74.25 174.25L74.25 169.25"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M78.25 179.25L78.25 168.25"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M70.25 171.53L70.25 178.97C70.25 179.125 70.3754 179.25 70.53 179.25L85.97 179.25C86.1246 179.25 86.25 179.125 86.25 178.97L86.25 171.53C86.25 171.375 86.1246 171.25 85.97 171.25L70.53 171.25C70.3754 171.25 70.25 171.375 70.25 171.53Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M70.25 163.53L70.25 170.97C70.25 171.125 70.3754 171.25 70.53 171.25L85.97 171.25C86.1246 171.25 86.25 171.125 86.25 170.97L86.25 163.53C86.25 163.375 86.1246 163.25 85.97 163.25L70.53 163.25C70.3754 163.25 70.25 163.375 70.25 163.53Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M70.25 154.53L70.25 161.97C70.25 162.125 70.3754 162.25 70.53 162.25L85.97 162.25C86.1246 162.25 86.25 162.125 86.25 161.97L86.25 154.53C86.25 154.375 86.1246 154.25 85.97 154.25L70.53 154.25C70.3754 154.25 70.25 154.375 70.25 154.53Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M69.25 153.25L69.25 180.25L87.25 180.25L87.25 153.25L69.25 153.25Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M87.25 176.25L87.25 177.25L88.25 177.25L88.25 176.25L87.25 176.25Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M87.25 174.25L87.25 175.25L88.25 175.25L88.25 174.25L87.25 174.25Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M87.25 172.25L87.25 173.25L88.25 173.25L88.25 172.25L87.25 172.25Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M87.25 170.25L87.25 171.25L88.25 171.25L88.25 170.25L87.25 170.25Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M87.25 168.25L87.25 169.25L88.25 169.25L88.25 168.25L87.25 168.25Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M87.25 164.25L87.25 165.25L88.25 165.25L88.25 164.25L87.25 164.25Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M87.25 160.25L87.25 161.25L88.25 161.25L88.25 160.25L87.25 160.25Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M64.25 133.25L64.25 201.25L67.25 201.25L67.25 133.25L64.25 133.25Z"
          fill="#FFCDB2"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M290.25 109.25L358.25 109.25L290.25 109.25Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M217.25 109.25L242.25 109.25L217.25 109.25Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M192.25 109.25L217.25 109.25L192.25 109.25Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M167.25 109.25L192.25 109.25L167.25 109.25Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M142.25 109.25L167.25 109.25L142.25 109.25Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M117.25 109.25L142.25 109.25L117.25 109.25Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M92.25 109.25L117.25 109.25L92.25 109.25Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M290.25 109.25L290.25 112.25L358.25 112.25L358.25 109.25L290.25 109.25Z"
          fill="#FFCDB2"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M217.25 109.25L217.25 112.25L242.25 112.25L242.25 109.25L217.25 109.25Z"
          fill="#FFCDB2"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M192.25 109.25L192.25 112.25L217.25 112.25L217.25 109.25L192.25 109.25Z"
          fill="#FFCDB2"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M167.25 109.25L167.25 112.25L192.25 112.25L192.25 109.25L167.25 109.25Z"
          fill="#FFCDB2"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M142.25 109.25L142.25 112.25L167.25 112.25L167.25 109.25L142.25 109.25Z"
          fill="#FFCDB2"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M117.25 109.25L117.25 112.25L142.25 112.25L142.25 109.25L117.25 109.25Z"
          fill="#FFCDB2"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M92.25 109.25L92.25 112.25L117.25 112.25L117.25 109.25L92.25 109.25Z"
          fill="#FFCDB2"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M67.25 112.25L67.25 244.25L87.25 244.25L87.25 112.25L67.25 112.25Z"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M242.25 109.25L242.25 112.25L290.25 112.25L290.25 109.25L242.25 109.25Z"
          fill="#FFCDB2"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M358.25 109.25L358.25 112.25L399.25 112.25L399.25 237.25L399.25 240.25L399.25 255.25L402.25 255.25L402.25 111.78L402.25 108.78L402.25 100.25L399.25 100.25L399.25 109.25L358.25 109.25Z"
          fill="#FFCDB2"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M64.25 0.25L64.25 0.719971L64.25 109.07L64.25 112.07L64.25 133.25L67.25 133.25L67.25 112.25L92.25 112.25L92.25 109.25L67.25 109.25L67.25 0.25L64.25 0.25Z"
          fill="#FFCDB2"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M64.25 244.26L64.25 250.25L67.25 250.25L67.25 247.25L90.25 247.25L90.25 244.25L67.25 244.25L67.25 201.25L64.25 201.25L64.25 244.26Z"
          fill="#FFCDB2"
          stroke="#FF634D"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M396.25 147.58L387.25 147.58L387.25 200.58L396.25 200.58L396.25 147.58Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M394.25 154.58L392.25 154.58L392.25 193.58L394.25 193.58L394.25 154.58Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M304.25 123.58L304.25 224.58L365.25 224.58L365.25 123.58L304.25 123.58Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M304.25 185.58L340.25 185.58C340.8 185.58 341.25 186.03 341.25 186.58L341.25 207.58C341.25 208.13 340.8 208.58 340.25 208.58L304.25 208.58L304.25 185.58V185.58Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M350.25 151.58L334.25 151.58C332.593 151.58 331.25 152.923 331.25 154.58L331.25 170.58C331.25 172.237 332.593 173.58 334.25 173.58L350.25 173.58C351.907 173.58 353.25 172.237 353.25 170.58L353.25 154.58C353.25 152.923 351.907 151.58 350.25 151.58Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M321.25 162.58L304.25 162.58L304.25 185.58L321.25 185.58L321.25 162.58Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M321.25 139.58L304.25 139.58L304.25 162.58L321.25 162.58L321.25 139.58Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M302.25 139.58L297.25 139.58L297.25 162.58L297.25 185.58L297.25 208.58L302.25 208.58"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M321.25 134.58L297.25 134.58L297.25 139.58L321.25 139.58L321.25 134.58Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M321.25 208.58L297.25 208.58L297.25 213.58L321.25 213.58L321.25 208.58Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M307.25 205.58L307.25 188.58C307.25 186.923 305.907 185.58 304.25 185.58C302.593 185.58 301.25 186.923 301.25 188.58L301.25 205.58C301.25 207.237 302.593 208.58 304.25 208.58C305.907 208.58 307.25 207.237 307.25 205.58Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M307.25 182.58L307.25 165.58C307.25 163.923 305.907 162.58 304.25 162.58C302.593 162.58 301.25 163.923 301.25 165.58L301.25 182.58C301.25 184.237 302.593 185.58 304.25 185.58C305.907 185.58 307.25 184.237 307.25 182.58Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M307.25 159.58L307.25 142.58C307.25 140.923 305.907 139.58 304.25 139.58C302.593 139.58 301.25 140.923 301.25 142.58L301.25 159.58C301.25 161.237 302.593 162.58 304.25 162.58C305.907 162.58 307.25 161.237 307.25 159.58Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M304.25 185.58L297.25 185.58"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M147.439 146.25C147.439 149.56 144.749 152.25 141.439 152.25L139.149 152.25C138.199 152.25 137.439 151.48 137.439 150.54L137.439 141.96C137.439 141.01 138.209 140.25 139.149 140.25L141.439 140.25C144.749 140.25 147.439 142.94 147.439 146.25V146.25Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M148.449 146.26C148.449 143.44 146.779 141.02 144.379 139.91C143.709 139.6 142.949 140.1 142.949 140.83C142.949 141.21 143.159 141.57 143.509 141.73C145.239 142.51 146.449 144.24 146.449 146.26C146.449 148.28 145.239 150 143.509 150.79C143.159 150.95 142.949 151.31 142.949 151.69C142.949 152.43 143.719 152.92 144.379 152.61C146.779 151.5 148.449 149.07 148.449 146.26Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M147.439 162.25C147.439 165.56 144.749 168.25 141.439 168.25L139.149 168.25C138.199 168.25 137.439 167.48 137.439 166.54L137.439 157.96C137.439 157.01 138.209 156.25 139.149 156.25L141.439 156.25C144.749 156.25 147.439 158.94 147.439 162.25V162.25Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M148.449 162.26C148.449 159.44 146.779 157.02 144.379 155.91C143.709 155.6 142.949 156.1 142.949 156.83C142.949 157.21 143.159 157.57 143.509 157.73C145.239 158.51 146.449 160.24 146.449 162.26C146.449 164.28 145.239 166 143.509 166.79C143.159 166.95 142.949 167.31 142.949 167.69C142.949 168.43 143.719 168.92 144.379 168.61C146.779 167.5 148.449 165.07 148.449 162.26Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M147.439 178.25C147.439 181.56 144.749 184.25 141.439 184.25L139.149 184.25C138.199 184.25 137.439 183.48 137.439 182.54L137.439 173.96C137.439 173.01 138.209 172.25 139.149 172.25L141.439 172.25C144.749 172.25 147.439 174.94 147.439 178.25V178.25Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M148.449 178.26C148.449 175.44 146.779 173.02 144.379 171.91C143.709 171.6 142.949 172.1 142.949 172.83C142.949 173.21 143.159 173.57 143.509 173.73C145.239 174.51 146.449 176.24 146.449 178.26C146.449 180.28 145.239 182 143.509 182.79C143.159 182.95 142.949 183.31 142.949 183.69C142.949 184.43 143.719 184.92 144.379 184.61C146.779 183.5 148.449 181.07 148.449 178.26Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M114.449 135.26L114.449 220.26L140.449 220.26L140.449 135.26L114.449 135.26Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-linejoin="round"
        />
        <path
          d="M129.439 204.26L121.439 204.26C120.335 204.26 119.439 205.156 119.439 206.26L119.439 211.26C119.439 212.365 120.335 213.26 121.439 213.26L129.439 213.26C130.544 213.26 131.439 212.365 131.439 211.26L131.439 206.26C131.439 205.156 130.544 204.26 129.439 204.26Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M129.439 193.26L121.439 193.26C120.335 193.26 119.439 194.156 119.439 195.26L119.439 200.26C119.439 201.365 120.335 202.26 121.439 202.26L129.439 202.26C130.544 202.26 131.439 201.365 131.439 200.26L131.439 195.26C131.439 194.156 130.544 193.26 129.439 193.26Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M128.449 197.76C128.449 196.932 127.778 196.26 126.949 196.26C126.121 196.26 125.449 196.932 125.449 197.76C125.449 198.589 126.121 199.26 126.949 199.26C127.778 199.26 128.449 198.589 128.449 197.76Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M128.449 208.76C128.449 207.932 127.778 207.26 126.949 207.26C126.121 207.26 125.449 207.932 125.449 208.76C125.449 209.589 126.121 210.26 126.949 210.26C127.778 210.26 128.449 209.589 128.449 208.76Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M132.439 191.26L118.439 191.26C117.887 191.26 117.439 191.708 117.439 192.26L117.439 214.26C117.439 214.812 117.887 215.26 118.439 215.26L132.439 215.26C132.992 215.26 133.439 214.812 133.439 214.26L133.439 192.26C133.439 191.708 132.992 191.26 132.439 191.26Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M189.439 157.53L189.439 166.11C189.439 167.06 188.669 167.82 187.729 167.82L185.439 167.82C182.129 167.82 179.439 165.13 179.439 161.82C179.439 158.51 182.129 155.82 185.439 155.82L187.729 155.82C188.679 155.82 189.439 156.59 189.439 157.53Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M178.59 161.76C178.59 158.94 180.26 156.52 182.66 155.41C183.33 155.1 184.09 155.6 184.09 156.33C184.09 156.71 183.88 157.07 183.53 157.23C181.8 158.01 180.59 159.74 180.59 161.76C180.59 163.78 181.8 165.5 183.53 166.29C183.88 166.45 184.09 166.81 184.09 167.19C184.09 167.93 183.32 168.42 182.66 168.11C180.26 167 178.59 164.57 178.59 161.76Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M189.439 173.53L189.439 182.11C189.439 183.06 188.669 183.82 187.729 183.82L185.439 183.82C182.129 183.82 179.439 181.13 179.439 177.82C179.439 174.51 182.129 171.82 185.439 171.82L187.729 171.82C188.679 171.82 189.439 172.59 189.439 173.53Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M178.59 177.76C178.59 174.94 180.26 172.52 182.66 171.41C183.33 171.1 184.09 171.6 184.09 172.33C184.09 172.71 183.88 173.07 183.53 173.23C181.8 174.01 180.59 175.74 180.59 177.76C180.59 179.78 181.8 181.5 183.53 182.29C183.88 182.45 184.09 182.81 184.09 183.19C184.09 183.93 183.32 184.42 182.66 184.11C180.26 183 178.59 180.57 178.59 177.76Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M189.439 189.53L189.439 198.11C189.439 199.06 188.669 199.82 187.729 199.82L185.439 199.82C182.129 199.82 179.439 197.13 179.439 193.82C179.439 190.51 182.129 187.82 185.439 187.82L187.729 187.82C188.679 187.82 189.439 188.59 189.439 189.53Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M178.59 193.76C178.59 190.94 180.26 188.52 182.66 187.41C183.33 187.1 184.09 187.6 184.09 188.33C184.09 188.71 183.88 189.07 183.53 189.23C181.8 190.01 180.59 191.74 180.59 193.76C180.59 195.78 181.8 197.5 183.53 198.29C183.88 198.45 184.09 198.81 184.09 199.19C184.09 199.93 183.32 200.42 182.66 200.11C180.26 199 178.59 196.57 178.59 193.76Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M217.439 161.82C217.439 165.13 214.749 167.82 211.439 167.82L209.149 167.82C208.199 167.82 207.439 167.05 207.439 166.11L207.439 157.53C207.439 156.58 208.209 155.82 209.149 155.82L211.439 155.82C214.749 155.82 217.439 158.51 217.439 161.82Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M218.59 161.76C218.59 158.94 216.92 156.52 214.52 155.41C213.85 155.1 213.09 155.6 213.09 156.33C213.09 156.71 213.3 157.07 213.65 157.23C215.38 158.01 216.59 159.74 216.59 161.76C216.59 163.78 215.38 165.5 213.65 166.29C213.3 166.45 213.09 166.81 213.09 167.19C213.09 167.93 213.86 168.42 214.52 168.11C216.92 167 218.59 164.57 218.59 161.76Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M217.439 177.82C217.439 181.13 214.749 183.82 211.439 183.82L209.149 183.82C208.199 183.82 207.439 183.05 207.439 182.11L207.439 173.53C207.439 172.58 208.209 171.82 209.149 171.82L211.439 171.82C214.749 171.82 217.439 174.51 217.439 177.82Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M218.59 177.76C218.59 174.94 216.92 172.52 214.52 171.41C213.85 171.1 213.09 171.6 213.09 172.33C213.09 172.71 213.3 173.07 213.65 173.23C215.38 174.01 216.59 175.74 216.59 177.76C216.59 179.78 215.38 181.5 213.65 182.29C213.3 182.45 213.09 182.81 213.09 183.19C213.09 183.93 213.86 184.42 214.52 184.11C216.92 183 218.59 180.57 218.59 177.76Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M217.439 193.82C217.439 197.13 214.749 199.82 211.439 199.82L209.149 199.82C208.199 199.82 207.439 199.05 207.439 198.11L207.439 189.53C207.439 188.58 208.209 187.82 209.149 187.82L211.439 187.82C214.749 187.82 217.439 190.51 217.439 193.82Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M218.59 193.76C218.59 190.94 216.92 188.52 214.52 187.41C213.85 187.1 213.09 187.6 213.09 188.33C213.09 188.71 213.3 189.07 213.65 189.23C215.38 190.01 216.59 191.74 216.59 193.76C216.59 195.78 215.38 197.5 213.65 198.29C213.3 198.45 213.09 198.81 213.09 199.19C213.09 199.93 213.86 200.42 214.52 200.11C216.92 199 218.59 196.57 218.59 193.76Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M198.449 212.82C195.139 212.82 192.449 210.13 192.449 206.82L192.449 204.53C192.449 203.58 193.219 202.82 194.159 202.82L202.739 202.82C203.689 202.82 204.449 203.59 204.449 204.53L204.449 206.82C204.449 210.13 201.759 212.82 198.449 212.82Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M198.589 213.76C201.409 213.76 203.829 212.09 204.939 209.69C205.249 209.02 204.749 208.26 204.019 208.26C203.639 208.26 203.279 208.47 203.119 208.82C202.339 210.55 200.609 211.76 198.589 211.76C196.569 211.76 194.849 210.55 194.059 208.82C193.899 208.47 193.539 208.26 193.159 208.26C192.419 208.26 191.929 209.03 192.239 209.69C193.349 212.09 195.779 213.76 198.589 213.76Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M202.739 152.82L194.159 152.82C193.209 152.82 192.449 152.05 192.449 151.11L192.449 148.82C192.449 145.51 195.139 142.82 198.449 142.82C201.759 142.82 204.449 145.51 204.449 148.82L204.449 151.11C204.449 152.06 203.679 152.82 202.739 152.82Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M198.589 141.76C201.409 141.76 203.829 143.43 204.939 145.83C205.249 146.5 204.749 147.26 204.019 147.26C203.639 147.26 203.279 147.05 203.119 146.7C202.339 144.97 200.609 143.76 198.589 143.76C196.569 143.76 194.849 144.97 194.059 146.7C193.899 147.05 193.539 147.26 193.159 147.26C192.419 147.26 191.929 146.49 192.239 145.83C193.349 143.43 195.779 141.76 198.589 141.76Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M186.449 150.82L186.449 204.82C186.449 205.372 186.897 205.82 187.449 205.82L209.449 205.82C210.002 205.82 210.449 205.372 210.449 204.82L210.449 150.82C210.449 150.268 210.002 149.82 209.449 149.82L187.449 149.82C186.897 149.82 186.449 150.268 186.449 150.82Z"
          fill="#FFCDB2"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M321.211 61.8302L309.121 52.4702C309.121 52.4702 308.761 42.8202 308.971 41.5402C309.181 40.2602 316.121 36.2602 317.021 35.6602C317.921 35.0602 324.131 33.1202 324.541 33.5402C324.951 33.9602 328.971 46.2502 329.371 46.9502C329.771 47.6502 332.681 52.3902 332.401 53.4702C332.121 54.5502 329.511 60.9102 329.511 60.9102L321.211 61.8202V61.8302Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M338.411 25.3101L351.831 32.6401C351.831 32.6401 353.711 42.1101 353.711 43.4001C353.711 44.6901 347.481 49.7401 346.691 50.4701C345.901 51.2001 340.071 54.1001 339.601 53.7501C339.131 53.4001 333.211 41.9001 332.711 41.2801C332.211 40.6601 328.581 36.4301 328.691 35.3201C328.801 34.2101 330.371 27.5101 330.371 27.5101L338.421 25.3001L338.411 25.3101Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M262.731 54.5802L257.001 40.4002C257.001 40.4002 261.551 31.8802 262.371 30.8802C263.191 29.8802 271.211 29.9302 272.291 29.8602C273.371 29.8002 279.721 31.2502 279.851 31.8102C279.981 32.3702 277.271 45.0302 277.271 45.8302C277.271 46.6302 277.391 52.1902 276.601 52.9902C275.811 53.7802 270.351 57.9602 270.351 57.9602L262.721 54.5702L262.731 54.5802Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M294.23 34.3502L305.07 45.1302C305.07 45.1302 304.24 54.7502 303.87 55.9902C303.5 57.2302 296.12 60.3402 295.15 60.8202C294.18 61.3002 287.78 62.4602 287.43 62.0002C287.08 61.5402 284.6 48.8402 284.29 48.1002C283.98 47.3602 281.68 42.2902 282.09 41.2602C282.5 40.2302 285.88 34.2302 285.88 34.2302L294.22 34.3502H294.23Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M351.54 23.7102L356.57 38.1502C356.57 38.1502 365.6 41.5602 366.88 41.7702C368.16 41.9802 374.16 36.6602 375.01 36.0002C375.86 35.3402 379.68 30.0602 379.41 29.5502C379.14 29.0402 368.76 21.3102 368.23 20.7102C367.7 20.1102 364.13 15.8402 363.01 15.7702C361.89 15.7002 355.03 16.1502 355.03 16.1502L351.53 23.7302L351.54 23.7102Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M298.249 11.59C301.609 13.08 304.429 14.13 307.059 16.24"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M306.229 6.57001C307.109 9.72001 307.389 13.01 307.039 16.26"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M315.709 10.64C312.599 12.14 309.699 14.02 307.049 16.23"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M316.27 21.2101C313.01 19.9201 309.91 18.2401 307.05 16.2101"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M307.328 25.9301C306.558 22.7201 306.458 19.3901 307.058 16.1501"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M298.569 21.3401C301.669 20.1601 304.539 18.4301 307.049 16.2501"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M305.18 19.8C304.91 20.1 304.61 20.37 304.29 20.61C301.67 22.53 297.99 21.61 297.99 21.61C299 19.74 300.43 18.13 302.17 16.9C302.52 16.69 302.89 16.51 303.27 16.36"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M307.059 16.24C305.399 16.84 303.559 16.69 302.009 15.84C300.219 14.71 298.719 13.19 297.619 11.38C297.619 11.38 301.469 10.23 304.139 12.04C305.659 12.97 306.719 14.49 307.059 16.24V16.24Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M307.078 16.13C305.818 17.6 305.088 19.45 305.008 21.39C305.218 23.31 306.038 25.12 307.348 26.55C308.648 24.78 309.248 22.6 309.048 20.41L308.978 19.9001"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M307.049 16.2C307.049 16.2 307.799 20.28 311.509 21.46C315.219 22.64 316.789 21.36 316.789 21.36C316.789 21.36 314.719 17.62 311.529 16.54C308.339 15.46 307.049 16.2 307.049 16.2V16.2Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M310.569 16.24C311.439 15.96 312.239 15.51 312.929 14.91C315.849 12.45 316.199 10.35 316.199 10.35C313.479 9.81998 310.679 10.76 308.839 12.83"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M307.038 16.26C309.638 13.63 309.618 9.39003 306.988 6.78003C306.698 6.49003 306.378 6.23 306.038 6C305.028 7.92 304.708 10.13 305.118 12.26C305.178 12.52 305.238 12.77 305.308 13"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M271.251 75.59C274.611 77.08 277.431 78.13 280.061 80.24"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M279.231 70.57C280.111 73.72 280.391 77.01 280.041 80.26"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M288.711 74.64C285.601 76.14 282.701 78.02 280.051 80.23"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M289.271 85.2101C286.011 83.9201 282.911 82.2401 280.051 80.2101"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M280.33 89.9301C279.56 86.7201 279.46 83.3901 280.06 80.1501"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M271.571 85.3401C274.671 84.1601 277.541 82.4301 280.051 80.2501"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M278.182 83.8C277.912 84.1 277.612 84.37 277.292 84.61C274.672 86.53 270.992 85.61 270.992 85.61C272.002 83.74 273.432 82.13 275.172 80.9C275.522 80.69 275.892 80.51 276.272 80.36"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M280.061 80.24C278.401 80.84 276.561 80.69 275.011 79.84C273.221 78.71 271.721 77.19 270.621 75.38C270.621 75.38 274.471 74.23 277.141 76.04C278.661 76.97 279.721 78.49 280.061 80.24V80.24Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M280.08 80.13C278.82 81.6 278.09 83.45 278.01 85.39C278.22 87.31 279.04 89.12 280.35 90.55C281.65 88.78 282.25 86.6 282.05 84.41L281.98 83.9001"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M280.051 80.2C280.051 80.2 280.801 84.28 284.511 85.46C288.221 86.64 289.791 85.36 289.791 85.36C289.791 85.36 287.721 81.62 284.531 80.54C281.341 79.46 280.051 80.2 280.051 80.2V80.2Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M283.571 80.24C284.441 79.96 285.241 79.51 285.931 78.91C288.851 76.45 289.201 74.35 289.201 74.35C286.481 73.82 283.681 74.76 281.841 76.83"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M280.04 80.26C282.64 77.63 282.62 73.39 279.99 70.78C279.7 70.49 279.38 70.23 279.04 70C278.03 71.92 277.71 74.13 278.12 76.26C278.18 76.52 278.24 76.77 278.31 77"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M347.251 65.59C350.611 67.08 353.431 68.13 356.061 70.24"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M355.231 60.57C356.111 63.72 356.391 67.01 356.041 70.26"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M364.711 64.64C361.601 66.14 358.701 68.02 356.051 70.23"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M365.271 75.2101C362.011 73.9201 358.911 72.2401 356.051 70.2101"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M356.33 79.9301C355.56 76.7201 355.46 73.3901 356.06 70.1501"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M347.571 75.3401C350.671 74.1601 353.541 72.4301 356.051 70.2501"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M354.182 73.8C353.912 74.1 353.612 74.37 353.292 74.61C350.672 76.53 346.992 75.61 346.992 75.61C348.002 73.74 349.432 72.13 351.172 70.9C351.522 70.69 351.892 70.51 352.272 70.36"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M356.061 70.24C354.401 70.84 352.561 70.69 351.011 69.84C349.221 68.71 347.721 67.19 346.621 65.38C346.621 65.38 350.471 64.23 353.141 66.04C354.661 66.97 355.721 68.49 356.061 70.24V70.24Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M356.08 70.13C354.82 71.6 354.09 73.45 354.01 75.39C354.22 77.31 355.04 79.12 356.35 80.55C357.65 78.78 358.25 76.6 358.05 74.41L357.98 73.9001"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M356.051 70.2C356.051 70.2 356.801 74.28 360.511 75.46C364.221 76.64 365.791 75.36 365.791 75.36C365.791 75.36 363.721 71.62 360.531 70.54C357.341 69.46 356.051 70.2 356.051 70.2V70.2Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M359.571 70.24C360.441 69.96 361.241 69.51 361.931 68.91C364.851 66.45 365.201 64.35 365.201 64.35C362.481 63.82 359.681 64.76 357.841 66.83"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M356.04 70.26C358.64 67.63 358.62 63.39 355.99 60.78C355.7 60.49 355.38 60.23 355.04 60C354.03 61.92 353.71 64.13 354.12 66.26C354.18 66.52 354.24 66.77 354.31 67"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M28.0898 159.67C28.6421 159.67 29.0898 159.222 29.0898 158.67C29.0898 158.118 28.6421 157.67 28.0898 157.67C27.5376 157.67 27.0898 158.118 27.0898 158.67C27.0898 159.222 27.5376 159.67 28.0898 159.67Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M30.0898 159.67C30.6421 159.67 31.0898 159.222 31.0898 158.67C31.0898 158.118 30.6421 157.67 30.0898 157.67C29.5376 157.67 29.0898 158.118 29.0898 158.67C29.0898 159.222 29.5376 159.67 30.0898 159.67Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M29.0898 161.67C29.6421 161.67 30.0898 161.222 30.0898 160.67C30.0898 160.118 29.6421 159.67 29.0898 159.67C28.5376 159.67 28.0898 160.118 28.0898 160.67C28.0898 161.222 28.5376 161.67 29.0898 161.67Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M33.0898 161.67C33.6421 161.67 34.0898 161.222 34.0898 160.67C34.0898 160.118 33.6421 159.67 33.0898 159.67C32.5376 159.67 32.0898 160.118 32.0898 160.67C32.0898 161.222 32.5376 161.67 33.0898 161.67Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M31.0898 181.67C31.6421 181.67 32.0898 181.222 32.0898 180.67C32.0898 180.118 31.6421 179.67 31.0898 179.67C30.5376 179.67 30.0898 180.118 30.0898 180.67C30.0898 181.222 30.5376 181.67 31.0898 181.67Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M36.7704 145.63C33.5304 148 29.7204 155.52 29.4004 157.9C29.4004 157.9 32.2004 155.46 34.3104 152.01C36.4204 148.56 36.7604 145.63 36.7604 145.63H36.7704Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M28.8008 148.6C27.0808 150.57 27.2808 155.55 28.1208 156.95C28.1208 156.95 29.4207 155.03 29.6507 152.67C29.8807 150.31 28.8008 148.59 28.8008 148.59V148.6Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M37.17 160.33C34.57 160.61 31.34 164.41 31 166.01C31 166.01 33.26 165.48 35.02 163.9C36.78 162.32 37.16 160.33 37.16 160.33H37.17Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M41.03 156.54C38.53 155.99 33.81 158.01 32.75 159.17C32.75 159.17 35.06 159.43 37.41 158.71C39.76 157.99 41.03 156.54 41.03 156.54Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M43.0797 169.13C40.2797 167.72 34.0397 168.65 32.4297 169.69C32.4297 169.69 35.0897 170.7 38.0997 170.57C41.1097 170.44 43.0697 169.13 43.0697 169.13H43.0797Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M41.7809 147.99C38.0709 149.53 32.5809 155.92 31.7109 158.16C31.7109 158.16 35.011 156.46 37.881 153.61C40.751 150.76 41.7809 148 41.7809 148V147.99Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M32.0898 176.67C32.6421 176.67 33.0898 176.222 33.0898 175.67C33.0898 175.118 32.6421 174.67 32.0898 174.67C31.5376 174.67 31.0898 175.118 31.0898 175.67C31.0898 176.222 31.5376 176.67 32.0898 176.67Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M33.0898 179.67C33.6421 179.67 34.0898 179.222 34.0898 178.67C34.0898 178.118 33.6421 177.67 33.0898 177.67C32.5376 177.67 32.0898 178.118 32.0898 178.67C32.0898 179.222 32.5376 179.67 33.0898 179.67Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M45.9402 186.14C44.0102 182.61 37.0602 177.86 34.7402 177.23C34.7402 177.23 36.7902 180.33 39.9402 182.87C43.0902 185.41 45.9402 186.14 45.9402 186.14Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M45.6105 179.18C43.2505 176.74 36.5105 174.75 34.4805 174.9C34.4805 174.9 36.8405 176.94 39.9805 178.17C43.1205 179.4 45.6105 179.17 45.6105 179.17V179.18Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M34.0993 188.69C35.2193 186.47 34.3593 181.59 33.4793 180.34C33.4793 180.34 32.6893 182.45 32.8393 184.81C32.9893 187.17 34.1093 188.69 34.1093 188.69H34.0993Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M41.0197 188.41C40.5897 185.17 36.2297 180.17 34.4297 179.31C34.4297 179.31 35.1097 182.21 36.9497 184.8C38.7897 187.39 41.0197 188.41 41.0197 188.41Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M27.0898 177.67C27.6421 177.67 28.0898 177.222 28.0898 176.67C28.0898 176.118 27.6421 175.67 27.0898 175.67C26.5376 175.67 26.0898 176.118 26.0898 176.67C26.0898 177.222 26.5376 177.67 27.0898 177.67Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M26.0898 179.67C26.6421 179.67 27.0898 179.222 27.0898 178.67C27.0898 178.118 26.6421 177.67 26.0898 177.67C25.5376 177.67 25.0898 178.118 25.0898 178.67C25.0898 179.222 25.5376 179.67 26.0898 179.67Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M32.3698 192.33C32.5798 188.32 29.1498 180.62 27.5098 178.87C27.5098 178.87 27.6297 182.58 28.9697 186.4C30.3097 190.22 32.3698 192.33 32.3698 192.33Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M20.9502 188.29C23.0702 186.99 24.9001 182.38 24.8001 180.85C24.8001 180.85 23.0201 182.23 21.9101 184.32C20.8001 186.41 20.9502 188.29 20.9502 188.29Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M27.0093 192.59C28.3393 189.36 27.2393 182.28 26.1493 180.47C26.1493 180.47 25.2193 183.53 25.4293 186.95C25.6393 190.37 27.0093 192.58 27.0093 192.58V192.59Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M19.0898 170.67C19.6421 170.67 20.0898 170.222 20.0898 169.67C20.0898 169.118 19.6421 168.67 19.0898 168.67C18.5376 168.67 18.0898 169.118 18.0898 169.67C18.0898 170.222 18.5376 170.67 19.0898 170.67Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M3.96094 165.27C6.03094 166.66 10.981 166.4 12.331 165.68C12.331 165.68 10.3409 164.64 7.97095 164.49C5.60095 164.34 3.96094 165.27 3.96094 165.27Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M3.61914 172.76C7.51914 173.73 15.7292 171.83 17.7592 170.55C17.7592 170.55 14.0891 169.96 10.0891 170.55C6.08911 171.14 3.61914 172.76 3.61914 172.76Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M20.7405 147.87C22.9705 151.21 23.9705 159.58 23.4505 161.92C23.4505 161.92 21.6505 158.67 20.8505 154.7C20.0505 150.73 20.7305 147.87 20.7305 147.87H20.7405Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M25.2693 144C26.8593 147.69 26.3392 156.1 25.4092 158.31C25.4092 158.31 24.2292 154.79 24.1592 150.74C24.0892 146.69 25.2693 144 25.2693 144V144Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M14.6602 148.09C17.7402 150.68 21.0302 158.43 21.1902 160.82C21.1902 160.82 18.5602 158.2 16.6802 154.61C14.8002 151.02 14.6602 148.08 14.6602 148.08V148.09Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M18.0898 167.67C18.6421 167.67 19.0898 167.222 19.0898 166.67C19.0898 166.118 18.6421 165.67 18.0898 165.67C17.5376 165.67 17.0898 166.118 17.0898 166.67C17.0898 167.222 17.5376 167.67 18.0898 167.67Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M19.0898 164.67C19.6421 164.67 20.0898 164.222 20.0898 163.67C20.0898 163.118 19.6421 162.67 19.0898 162.67C18.5376 162.67 18.0898 163.118 18.0898 163.67C18.0898 164.222 18.5376 164.67 19.0898 164.67Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M3.01001 160.52C5.87001 163.5 14.06 165.57 16.53 165.22C16.53 165.22 13.66 162.76 9.84998 161.4C6.03998 160.03 3 160.52 3 160.52H3.01001Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M6.67017 168.82C9.43017 170.23 15.5802 169.3 17.1702 168.26C17.1702 168.26 14.5402 167.25 11.5702 167.38C8.60019 167.51 6.66016 168.82 6.66016 168.82H6.67017Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M14.6908 154.77C14.3508 157.24 16.7508 161.57 17.9808 162.47C17.9808 162.47 18.0509 160.22 17.1409 158.03C16.2309 155.84 14.6808 154.77 14.6808 154.77H14.6908Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M5.31079 154.95C7.40079 158.38 14.5708 162.81 16.9108 163.32C16.9108 163.32 14.7208 160.32 11.4508 157.93C8.18081 155.54 5.30078 154.95 5.30078 154.95H5.31079Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M22.0898 177.67C22.6421 177.67 23.0898 177.222 23.0898 176.67C23.0898 176.118 22.6421 175.67 22.0898 175.67C21.5376 175.67 21.0898 176.118 21.0898 176.67C21.0898 177.222 21.5376 177.67 22.0898 177.67Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M20.0898 176.67C20.6421 176.67 21.0898 176.222 21.0898 175.67C21.0898 175.118 20.6421 174.67 20.0898 174.67C19.5376 174.67 19.0898 175.118 19.0898 175.67C19.0898 176.222 19.5376 176.67 20.0898 176.67Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M22.0898 175.67C22.6421 175.67 23.0898 175.222 23.0898 174.67C23.0898 174.118 22.6421 173.67 22.0898 173.67C21.5376 173.67 21.0898 174.118 21.0898 174.67C21.0898 175.222 21.5376 175.67 22.0898 175.67Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M12.8691 186.14C15.9291 184.96 19.7792 179.55 20.1992 177.6C20.1992 177.6 17.5291 178.95 15.4391 181.34C13.3391 183.73 12.8691 186.14 12.8691 186.14Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M17.0302 189.9C19.6902 187.73 21.9702 181.08 21.8502 179.01C21.8502 179.01 19.6203 181.22 18.2303 184.29C16.8403 187.36 17.0302 189.9 17.0302 189.9Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M12.1797 173.88C14.2497 174.56 18.3197 173.11 19.2697 172.16C19.2697 172.16 17.3497 171.75 15.3397 172.22C13.3297 172.68 12.1897 173.88 12.1897 173.88H12.1797Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M6.4707 177.56C9.6307 178.35 15.8407 176.13 17.2807 174.78C17.2807 174.78 14.3507 174.35 11.2807 175.11C8.2207 175.86 6.4707 177.56 6.4707 177.56Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M19.6995 161.94C20.4395 164.24 24.1994 167.44 25.5894 167.89C25.5894 167.89 24.7094 165.86 23.0594 164.17C21.4094 162.47 19.6895 161.94 19.6895 161.94H19.6995Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M19.0801 167.86C20.6001 169.19 24.4201 168.98 25.4901 168.31C25.4901 168.31 24.0101 167.31 22.2001 167.15C20.3901 166.99 19.0801 167.85 19.0801 167.85V167.86Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M19.5195 173.46C21.9895 173.57 25.3395 170.38 25.7795 168.89C25.7795 168.89 23.6095 169.06 21.8195 170.33C20.0295 171.6 19.5195 173.47 19.5195 173.47V173.46Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M24.1891 177.51C25.9091 175.75 26.7291 170.87 26.3591 169.42C26.3591 169.42 24.9791 171.17 24.3391 173.45C23.6991 175.73 24.1891 177.51 24.1891 177.51Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M31.0902 178.67C31.3902 175.65 28.2802 170.46 26.7402 169.42C26.7402 169.42 26.7702 172.16 27.9602 174.79C29.1502 177.42 31.0803 178.67 31.0803 178.67H31.0902Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M36.7392 174.12C35.1492 171.69 29.7192 169.45 27.9492 169.49C27.9492 169.49 29.6192 171.54 32.0892 172.88C34.5592 174.22 36.7392 174.13 36.7392 174.13V174.12Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M35.6205 166.49C33.1705 165.57 28.5805 167.5 27.5605 168.78C27.5605 168.78 29.8105 169.33 32.1005 168.71C34.3905 168.09 35.6205 166.49 35.6205 166.49Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M31.6595 161.1C29.1995 161.97 26.9195 166.4 26.9595 168.04C26.9595 168.04 29.0395 167.01 30.3995 165.07C31.7595 163.13 31.6695 161.1 31.6695 161.1H31.6595Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M25.4798 159.42C24.3598 161.66 25.3299 166.53 26.2499 167.76C26.2499 167.76 27.0298 165.64 26.8398 163.28C26.6498 160.92 25.4798 159.42 25.4798 159.42Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M7.50977 183.27C11.0898 182.89 17.0697 178.49 18.2297 176.65C18.2297 176.65 14.9898 177.3 11.9398 179.14C8.88976 180.98 7.50977 183.26 7.50977 183.26V183.27Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M38.0898 164.67C38.6421 164.67 39.0898 164.222 39.0898 163.67C39.0898 163.118 38.6421 162.67 38.0898 162.67C37.5376 162.67 37.0898 163.118 37.0898 163.67C37.0898 164.222 37.5376 164.67 38.0898 164.67Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M39.0898 166.67C39.6421 166.67 40.0898 166.222 40.0898 165.67C40.0898 165.118 39.6421 164.67 39.0898 164.67C38.5376 164.67 38.0898 165.118 38.0898 165.67C38.0898 166.222 38.5376 166.67 39.0898 166.67Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M37.0898 166.67C37.6421 166.67 38.0898 166.222 38.0898 165.67C38.0898 165.118 37.6421 164.67 37.0898 164.67C36.5376 164.67 36.0898 165.118 36.0898 165.67C36.0898 166.222 36.5376 166.67 37.0898 166.67Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M51.1803 164.11C48.2103 162.54 41.3803 163.11 39.5703 164.05C39.5703 164.05 42.4103 165.21 45.6903 165.26C48.9703 165.31 51.1803 164.11 51.1803 164.11V164.11Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M47.7109 159.53C45.1809 158.9 40.8309 161.34 39.9609 162.72C39.9609 162.72 42.2609 163.01 44.4709 162.14C46.6809 161.27 47.7209 159.53 47.7209 159.53H47.7109Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M45.9505 154.98C43.1205 155.68 38.9105 159.93 38.2305 161.55C38.2305 161.55 40.7505 160.66 42.9505 158.83C45.1505 156.99 45.9505 154.98 45.9505 154.98Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M51.7399 169.6C49.3199 167.26 42.5898 165.9 40.5898 166.3C40.5898 166.3 42.9999 168.21 46.1399 169.18C49.2799 170.15 51.7399 169.61 51.7399 169.61V169.6Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M50.1199 171.36C46.5799 170.09 38.8799 171.34 36.9199 172.46C36.9199 172.46 40.2699 173.33 43.9999 173.05C47.7399 172.77 50.1199 171.36 50.1199 171.36Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M50.1299 176.28C47.6599 174.22 41.1998 173.58 39.3398 174.19C39.3398 174.19 41.7599 175.83 44.8099 176.45C47.8599 177.07 50.1299 176.28 50.1299 176.28V176.28Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M385.1 63.9901C380.04 64.9901 377.27 62.41 377.27 62.41C377.79 61.23 378.71 60.2601 379.86 59.6801"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M377.271 62.4101C379.961 62.1801 382.621 61.6901 385.211 60.9501"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M395.631 58.88C395.631 58.88 389.271 61.36 384.141 60.88C379.011 60.4 377.051 57.21 377.051 57.21C378.401 55.94 380.111 55.13 381.941 54.88"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M377 57.22L381.9 57.66"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M387.05 54.7001C391.23 55.5501 395.59 58.8801 395.59 58.8801C395.59 58.8801 390.02 60.1801 385.83 59.3201C381.64 58.4601 380.43 55.7801 380.43 55.7801C382.39 54.5001 384.79 54.1101 387.05 54.7001V54.7001Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M380.43 55.78C383.89 56.22 389.69 57.53 395.59 58.88"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M382.57 54.8001C379.28 52.3301 379.29 49.8001 379.29 49.8001C379.29 49.8001 382.18 48.3001 386.18 50.2301"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M379.29 49.8101C384.93 52.4501 390.37 55.4801 395.59 58.8801"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M395.631 58.88C395.631 58.88 390.131 55.97 387.321 52.09C384.511 48.21 385.461 44.85 385.461 44.85C387.341 44.97 389.051 45.99 390.061 47.58"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M385.42 44.85C388.53 49.71 391.93 54.39 395.59 58.85"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M396.181 48.0699C397.261 52.7299 395.591 58.92 395.591 58.92C395.591 58.92 391.351 54.0599 390.271 49.4099C389.541 46.8899 390.041 44.1599 391.631 42.0699C394.011 43.2399 395.691 45.4599 396.181 48.0699V48.0699Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M391.631 42.0699C392.861 47.0699 394.171 52.79 395.541 58.93"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M395.55 46.13C396.1 44.3 397.42 42.79 399.16 42C400.59 44.35 401.04 47.17 400.43 49.85"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M399.16 42C398.05 45.71 397.16 49.48 396.49 53.29"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M395.63 58.8799C395.63 58.8799 397.73 52.71 401.37 49.12C403.29 47.05 405.99 45.8699 408.81 45.8799C408.81 45.8799 409.45 49.3499 405.81 52.9399"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M408.77 45.88C404.15 49.98 399.76 54.32 395.59 58.88"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M395.631 59.0699C398.511 56.5699 401.761 54.5399 405.281 53.0699C410.171 51.2899 413.361 52.62 413.361 52.62C412.701 54.21 411.431 55.4799 409.841 56.1399"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M413.361 52.67C410.621 54.01 407.781 55.16 404.881 56.1"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M406.84 61.21C401.61 61.28 395.56 58.75 395.56 58.75C395.56 58.75 401.91 56.04 407.14 55.97C412.37 55.9 414.48 58.49 414.48 58.49C414.48 58.49 412.06 61.14 406.84 61.21V61.21Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M414.48 58.4901C408.17 58.1901 401.86 58.28 395.56 58.75"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M410 60.8199C411.62 61.4199 412.89 62.73 413.43 64.37C413.43 64.37 410.91 66.79 405.98 65.27"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M413.43 64.37C409.69 63.81 402.7 61.37 395.59 58.88"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M409.091 65.77C409.611 66.12 409.861 68.17 409.861 68.17C409.291 68.36 408.681 68.45 408.081 68.44"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M409.861 68.16L406.691 66.1"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M400.941 69.33C397.341 65.48 395.591 58.88 395.591 58.88C395.591 58.88 402.381 61.42 405.981 65.27C409.581 69.12 408.631 72.83 408.631 72.83C405.681 72.83 402.871 71.56 400.941 69.33V69.33Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M408.631 72.83C404.971 69.25 400.411 64.34 395.631 58.83"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M401.98 70.32C402.98 74.59 400.98 76.73 400.98 76.73C400.98 76.73 397.43 75.47 395.93 70.54C394.43 65.61 395.55 58.88 395.55 58.88"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M401.02 76.73C398.69 70.95 396.89 64.98 395.63 58.88"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M396.631 72.29C396.801 74.04 394.141 75.9 394.141 75.9C394.141 75.9 391.851 73.45 392.261 68.75C392.671 64.05 395.631 58.87 395.631 58.87"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M394.16 75.9C394.09 71.4 394.62 61.96 395.4 59.59"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M392.711 73.12C391.821 76.06 387.961 76.81 387.961 76.81C387.111 75.04 386.871 73.05 387.271 71.13"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M387.96 76.81C390.12 72.67 392.15 65.5 395.59 58.88"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M384.561 64.79C388.561 60.98 395.561 58.88 395.561 58.88C395.561 58.88 393.001 65.88 388.971 69.65C384.941 73.42 380.971 72.65 380.971 72.65C380.971 72.65 380.531 68.6 384.561 64.79V64.79Z"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M381 72.69C385.66 68.62 390.55 63.94 395.59 58.88"
          stroke="#FF8A5F"
          stroke-width="0.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_332_56466">
          <rect
            width="211"
            height="443"
            fill="white"
            transform="translate(0 211) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default HausSeries2
